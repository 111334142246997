import api from "../../services/api";

const useCharts = () => {
    
    const findTicketsGroupByQueues = async (params) => {
        const { data } = await api.request({
            url: `/statistics-tickets-queue`,
            method: 'GET',
            params
        });
        return data;
    } 

    const findTicketsGroupByChannels = async (params) => {
        const { data } = await api.request({
            url: `/statistics-tickets-channels`,
            method: 'GET',
            params
        });
        return data;
    } 

    const findTicketsGroupByTags = async (params) => {
        const { data } = await api.request({
            url: `/statistics-tickets-tags`,
            method: 'GET',
            params
        });
        return data;
    } 

    const findTicketsByDate = async (params) => {
        const { data } = await api.request({
            url: `/statistics-tickets-evolution-by-period`,
            method: 'GET',
            params
        });
        return data;
    } 



    return {
        findTicketsGroupByQueues,
        findTicketsGroupByChannels,
        findTicketsGroupByTags,
        findTicketsByDate
    }
}

export default useCharts;