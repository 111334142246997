import axios from "axios";

const api = axios.create({
	baseURL: "https://api.agilifyticket.com",
	withCredentials: true,
});

export const openApi = axios.create({
	baseURL: "https://api.agilifyticket.com"
});

export default api;
